import React, { useState } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	Popper,
	useMediaQuery,
	useTheme,
	Hidden,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import flt from '../assets/images/flt.png';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DateRangeNew from '../components/reusableComponents/DateRangeNew';
import BodyText from '../components/reusableComponents/BodyText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
	SearchReturnFormDispatch,
	SearchFormDispatch,
	SearchEtravFormDispatch,
	SearchTripjackFormDispatch,
	SearchTripjackRoundTripDispatch,
	SearchReturnFormIntDispatch,
	searchSuccessEtrav,
	AddSearchFlightRoundtripDispatch,
} from '../reducers/HomeReducer';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import OneWayDestopDate from '../components/reusableComponents/OneWayDestopDate';
import { useLocation } from 'react-router-dom';
import {
	fetchCityDispatch,
	AddSearchFlightDispatch,
	SearchReturnEtravFormDispatch,
} from '../reducers/HomeReducer';
import {
	setMod,
	setSelectedDepart,
	setSelectedOrigin,
	setSelectedTrip,
} from '../reducers/UiReducer';
import DateRange from '../components/reusableComponents/DateRange';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& input': {
			fontSize: 12,
			fontWeight: 600,
			borderRadius: 1,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-root': {
			borderRadius: 0,
		},
		// '& div': {
		// 	'& div': {
		// 		'& div': {
		// 			paddingRight: 0,
		// 		},
		// 	},
		// },
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'white',
			padding: 1,
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
			padding: 1,
		},
		'& .MuiTypography-body1': {
			color: 'white',
			fontSize: '10px',
			fontWeight: 800,
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
			padding: 1,
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
			padding: 1,
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '12px',
			// fontWeight: 800,
		},
	},
}));
const OneWayFlightForm = ({ id }) => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city, agentlogin } = useSelector((state) => state.home);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const { originp, departp } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const theme = useTheme();
	const [adult, setAdult] = useState(location?.state?.body?.AdultCount);
	const [child, setChild] = useState(location?.state?.body?.ChildCount);
	// const [modify,setMod] = useState(false)
	const [infant, setInfant] = useState(location?.state?.body?.InfantCount);
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const {
		origin,
		depart,
		selectedOrigin,
		selectedDepart,
		OriginO,
		tripInfo,
		modify,
	} = useSelector((state) => state.ui);
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const { dataO, setData } = useState(originp);
	const { dataD, setData2 } = useState(departp);
	// let invoiceOptions = [];
	// let demo = [];
	// city &&
	// 	city.data &&
	// 	city.data.length > 0 &&
	// 	city.data.forEach((invoice, index) => {
	// 		invoiceOptions.push({
	// 			code: invoice.iata_code,
	// 			city: invoice.city,
	// 			country: invoice.country_name,
	// 		});
	// 		if (index < 50) {
	// 			demo.push({
	// 				code: invoice.iata_code,
	// 				city: invoice.city,
	// 				country: invoice.country_name,
	// 			});
	// 		}
	// 	});
	console.log('Location', location);
	const handleSubmit = (data) => {
		console.log(data);
		let org = data.Origin;
		let des = data.Destination;
		console.log(
			'vAL',
			data.Destination
				? data.Destination
				: location.state.body.Segments[0].Destination
		);
		let formData = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 1,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: data.Origin,
					Destination: data.Destination,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
					// PreferredArrivalTime: '2022-03-18',
				},
				// {
				// 	Origin: 'CCU',
				// 	Destination: 'DEL',
				// 	FlightCabinClass: 1,
				// 	PreferredDepartureTime: '2022-03-18',
				// 	// PreferredArrivalTime: '2022-03-18',
				// },
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
			// agent_id: agentlogin&&agentlogin.status===200 ? agentlogin && agentlogin.result.result.id : 0,
		};
		let formData2 = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 2,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: data.Origin,
					Destination: data.Destination,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
					// PreferredArrivalTime: '2022-03-18',
				},
				{
					Origin: data.Destination,
					Destination: data.Origin,
					FlightCabinClass: 1,
					PreferredDepartureTime: depart,
					// PreferredArrivalTime: '2022-03-18',
				},
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		if (tripInfo === 'oneway') {
			dispatch(AddSearchFlightDispatch(formData, history));
			// dispatch(SearchFormDispatch(formData, history, org, des, origin));
			// dispatch(SearchEtravFormDispatch(formData, history, org, des, origin));

			// dispatch(SearchTripjackFormDispatch(formData))
			history.push({
				pathname: `/oneway/${data.Origin}/${data.Destination}/${origin}`,
				state: {
					body: formData,
					OriginO: OriginO,
				},
			});
		} else {
			if (
				selectedOrigin.country_code === 'IN' &&
				selectedDepart.country_code === 'IN'
			) {
				dispatch(AddSearchFlightRoundtripDispatch(formData2));
				// AddSearchFlightRoundtripDispatch
				// dispatch(AddSearchFlightDispatch(formData2));
				// dispatch(
				//   SearchReturnFormDispatch(
				//     formData2,
				//     history,
				//     selectedOrigin,
				//     selectedDepart
				//   )
				// );
				// dispatch(
				// 	SearchReturnEtravFormDispatch(
				// 		formData2,
				// 		history,
				// 		selectedOrigin,
				// 		selectedDepart
				// 	)
				// );
			} else {
				dispatch(AddSearchFlightDispatch(formData2));
				dispatch(SearchReturnFormIntDispatch(formData2));
			}

			// dispatch(
			//   SearchReturnEtravFormDispatch(
			// 	formData2,
			// 	history,
			// 	selectedOrigin,
			// 	selectedDepart
			//   )
			// );

			// dispatch(SearchTripjackRoundTripDispatch(location.state.returnBody))
			// dispatch(SearchTripjackRoundTripDispatch(formData2))
			// console.log(formData2);
			history.push({
				pathname:
					selectedOrigin.country_code === 'IN' &&
					selectedDepart.country_code === 'IN'
						? `/roundway/${data.Origin}/${data.Destination}/${origin}/${data.Destination}/${data.Origin}/${depart}`
						: '/international',
				state: { returnBody: formData2 },
			});
		}
	};
	// console.log(invoiceOptions);
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		console.log(value);
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchCityDispatch(cityData, setResult));
	};
	const PopperMy = function (props) {
		return (
			<Popper
				{...props}
				style={styles.popper}
				className={classes.popper}
				placement='bottom-start'
			/>
		);
	};

	const styles = (theme) => ({
		popper: {
			width: 'fit-content',
		},
	});
	// const handleSearch2 = (event) => {
	// 	let value = event.target.value.toLowerCase();
	// 	let results = [];
	// 	console.log(value);
	// 	if (value.length >= 2) {
	// 		results =
	// 			invoiceOptions &&
	// 			invoiceOptions.filter((data) => {
	// 				return Object.values(data).join('').toLowerCase().includes(value);
	// 			});
	// 		setResult2(results);
	// 	} else {
	// 		setResult2([]);
	// 	}
	// };
	// console.log(leaving);
	// console.log(going);
	const handleChange2 = (e) => {
		console.log(e.target.value);
		// if(e.target.value==='oneway'{
		dispatch(setSelectedTrip(e.target.value));
		// })
		// dispatch(setTrip(e.target.value));
	};

	return (
		<>
			<Formik
				initialValues={{
					Origin: selectedOrigin.airport_code,
					Destination: selectedDepart.airport_code,
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form
							style={{
								marginTop:
									matches && id === 1 ? 0 : matches && id === 3 ? 50 : 20,
							}}>
							{/* <Box className={classes.radio}>
								<FormControl onChange={handleChange2}>
									<RadioGroup
										row
										aria-labelledby='demo-row-radio-buttons-group-label'
										name='row-radio-buttons-group'>
										<FormControlLabel
											value='oneway'
											control={
												<Radio checked={tripInfo == 'oneway' ? true : false} />
											}
											label='ONE WAY'
										/>
										<FormControlLabel
											value='roundtrip'
											control={
												<Radio
													checked={tripInfo == 'roundtrip' ? true : false}
												/>
											}
											label='ROUND TRIP'
										/>
									</RadioGroup>
								</FormControl>
							</Box> */}
							<Grid container spacing={1} alignItems='center'>
								<Grid item xs={12} lg={1}>
									<Box
										display='flex'
										alignItems='center'
										justifyContent='space-between'>
										<Box className={classes.radio}>
											<FormControl onChange={handleChange2}>
												<RadioGroup
													row
													aria-labelledby='demo-row-radio-buttons-group-label'
													name='row-radio-buttons-group'>
													<FormControlLabel
														value='oneway'
														control={
															<Radio
																checked={tripInfo == 'oneway' ? true : false}
															/>
														}
														label='Oneway'
													/>
													<FormControlLabel
														value='roundtrip'
														control={
															<Radio
																checked={tripInfo == 'roundtrip' ? true : false}
															/>
														}
														label='Roundtrip'
													/>
												</RadioGroup>
											</FormControl>
										</Box>
										<Hidden only={['xl', 'lg', 'sm']}>
											<Box
												onClick={() => {
													modify
														? dispatch(setMod(false))
														: dispatch(setMod(true));
												}}>
												<span>Modify</span>
												{/* <span style={{color:'white'}}>Arrow</span> */}
												{modify ? (
													<ExpandLessIcon
														style={{ color: 'white', fontSize: '40px' }}
													/>
												) : (
													<ExpandMoreIcon
														style={{ color: 'white', fontSize: '40px' }}
													/>
												)}
											</Box>
										</Hidden>
									</Box>
								</Grid>
								{matches ? (
									modify ? (
										<>
											<Grid
												item
												xs={12}
												md={12}
												lg={tripInfo === 'roundtrip' ? 4 : 4}>
												<Grid container alignItems='center' spacing={2}>
													<Grid
														item
														xs={12}
														lg={6}
														style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: matches ? 10 : '',
														}}>
														<div
															className={classes.root}
															style={{ width: '100%' }}>
															<span
																style={{
																	color: 'white',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Leaving From
															</span>
															<Autocomplete
																id='country-select-demo'
																sx={{
																	background: 'white',
																	borderRadius: '4px',
																	padding: '8px',
																	transition: 'none',
																}}
																value={selectedOrigin}
																// defaultValue={{
																// 	code: originp,
																// 	city: 'dfedf',
																// 	country: 'edfe',
																// }}
																clearOnBlur={true}
																PopperComponent={PopperMy}
																options={
																	city && city.result.length > 0
																		? city.result
																		: []
																}
																filterOptions={(options, state) => options}
																autoHighlight
																getOptionLabel={(option) =>
																	`(${option.airport_code}) ${option.city_name}, ${option.country_name} `
																}
																renderOption={(props, option) => (
																	<Box
																		// mr={'5px'}
																		component='li'
																		sx={{ '& > img': { mr: 2 } }}
																		{...props}>
																		<img
																			loading='lazy'
																			width='20'
																			src={flt}
																			alt=''
																		/>
																		<p style={{ fontSize: 13 }}>
																			{option.city_name} ({option.airport_code}){' '}
																			{option.airport_name}
																		</p>
																	</Box>
																)}
																onChange={(event, value) => {
																	setFieldValue('Origin', value.airport_code);
																	console.log(value.airport_code);
																	dispatch(setSelectedOrigin(value));
																}}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		onChange={handleSearchOrigin}
																		name='origin'
																	/>
																)}
															/>
														</div>
														<img
															onClick={() => {
																dispatch(setSelectedDepart(selectedOrigin));
																dispatch(setSelectedOrigin(selectedDepart));
															}}
															src={
																'https://icons.veryicon.com/png/o/miscellaneous/mirror-icon/swap-8.png'
															}
															style={{
																height: 18,
																position: 'inherit',
																widht: '100%',
																marginTop: 20,
																marginLeft: 2,
																cursor: 'pointer',
															}}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<div className={classes.root}>
															<span
																style={{
																	color: 'white',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Going To
															</span>
															<Autocomplete
																id='country-select-demo'
																sx={{
																	background: 'white',
																	borderRadius: 0,
																	padding: '8px',
																}}
																options={
																	city && city.result.length > 0
																		? city.result
																		: []
																}
																PopperComponent={PopperMy}
																value={selectedDepart}
																autoSelect={true}
																// defaultValue={{
																// 	code: departp,
																// 	city: 'dfedf',
																// 	country: 'edfe',
																// }}
																clearOnBlur={true}
																filterOptions={(options, state) => options}
																autoHighlight
																getOptionLabel={(option) =>
																	`(${option.airport_code}) ${option.city_name}, ${option.country_name} `
																}
																renderOption={(props, option) => (
																	<Box
																		// mr={'5px'}
																		component='li'
																		sx={{ '& > img': { mr: 2 } }}
																		{...props}>
																		<img
																			loading='lazy'
																			width='20'
																			src={flt}
																			alt=''
																		/>
																		<p style={{ fontSize: 13 }}>
																			{option.city_name} ({option.airport_code}){' '}
																			{option.airport_name}
																		</p>
																	</Box>
																)}
																onChange={(event, value) => {
																	setFieldValue(
																		'Destination',
																		value.airport_code
																	);
																	console.log(value.airport_code);
																	dispatch(setSelectedDepart(value));
																}}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		onChange={handleSearchOrigin}
																	/>
																)}
															/>
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid
												item
												xs={12}
												md={12}
												lg={
													tripInfo === 'oneway'
														? 2
														: tripInfo === 'roundtrip'
														? 3
														: 2
												}>
												<Box display='flex' justifyContent='space-between'>
													<span
														style={{
															color: 'white',
															fontSize: matches ? 12 : '.8em',
														}}>
														Depart Date
													</span>
													{tripInfo === 'oneway' ? (
														''
													) : tripInfo === 'roundtrip' ? (
														<span
															style={{
																color: 'white',
																fontSize: matches ? 12 : '.8em',
															}}>
															Return Date
														</span>
													) : (
														''
													)}
												</Box>
												{tripInfo === 'oneway' ? (
													<OneWayDestopDate />
												) : // <></>
												tripInfo === 'roundtrip' ? (
													<DateRange />
												) : (
													<OneWayDestopDate />
												)}
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
												lg={tripInfo === 'oneway' ? 3 : 2}>
												<ul
													class='menu'
													style={{
														// marginTop: 20,
														borderRadius: 2,
														paddingLeft: 0,
														cursor: 'pointer',
														background: 'white',
														// width: trip == "roundtrip" ? "110%" : "95%",
													}}>
													<li>
														<div
															onClick={() =>
																toggle ? setToggle(false) : setToggle(true)
															}
															style={{
																padding: 8,
																fontSize: matches ? 12 : '.9em',
																borderRadius: 4,
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																textOverflow: 'ellipsis',
																fontWeight: 600,
																border: '1px solid lightgrey',
																textAlign: 'center',
															}}>
															Travellers,Classes
														</div>

														{toggle ? (
															<>
																{' '}
																<ul
																	style={{
																		padding: 5,
																		width: '77%',
																		height: 250,
																	}}>
																	<li>
																		<Box
																			style={{ padding: 5 }}
																			display='flex'
																			justifyContent='space-between'
																			textAlign='center'
																			alignItems='center'>
																			<BodyText
																				four
																				textStyle={{
																					color: 'black',
																					fontSize: 16,
																					fontWeight: 500,
																				}}>
																				Adult
																			</BodyText>
																			<Box display='flex' alignItems='center'>
																				<Box
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						adult === 0
																							? ''
																							: setAdult(adult - 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						-
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						{adult}
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						adult + child + infant === 9
																							? adult
																							: setAdult(adult + 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						+
																					</BodyText>
																				</Box>
																			</Box>
																		</Box>
																	</li>
																	<li>
																		<Box
																			style={{ padding: 5 }}
																			display='flex'
																			justifyContent='space-between'
																			textAlign='center'
																			alignItems='center'>
																			<BodyText
																				four
																				textStyle={{
																					color: 'black',
																					fontSize: 16,
																					fontWeight: 500,
																				}}>
																				Child
																			</BodyText>
																			<Box display='flex' alignItems='center'>
																				<Box
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						child === 0
																							? ''
																							: setChild(child - 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						-
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						{child}
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						adult + child + infant === 9
																							? child
																							: setChild(child + 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						+
																					</BodyText>
																				</Box>
																			</Box>
																		</Box>
																	</li>
																	<li>
																		<Box
																			style={{ padding: 5 }}
																			display='flex'
																			justifyContent='space-between'
																			textAlign='center'
																			alignItems='center'>
																			<BodyText
																				four
																				textStyle={{
																					color: 'black',
																					fontSize: 16,
																					fontWeight: 500,
																				}}>
																				Infant
																			</BodyText>
																			<Box display='flex' alignItems='center'>
																				<Box
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						infant === 0
																							? ''
																							: setInfant(infant - 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						-
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						{infant}
																					</BodyText>
																				</Box>
																				<Box
																					ml={'15px'}
																					style={{
																						cursor: 'pointer',
																						background: 'rgb(0, 55, 114)',
																						padding: '0px 10px',
																						borderRadius: '5px',
																					}}
																					onClick={() =>
																						adult + child + infant === 9
																							? infant
																							: setInfant(infant + 1)
																					}>
																					<BodyText
																						textStyle={{
																							fontSize: 16,
																							color: 'white',
																						}}>
																						+
																					</BodyText>
																				</Box>
																			</Box>
																		</Box>
																	</li>
																	<li>
																		<Box
																			style={{ padding: 10 }}
																			display='flex'
																			alignItems='center'
																			justifyContent='space-between'>
																			<Box className={classes.radio2}>
																				<FormControl component='fieldset'>
																					<RadioGroup
																						aria-label='gender'
																						name='gender1'
																						value={value}
																						color='black'
																						onChange={handleRadio}>
																						<FormControlLabel
																							value='Economy'
																							control={<Radio />}
																							label='Economy'
																						/>
																						<FormControlLabel
																							value='Business'
																							control={<Radio />}
																							label='Business'
																						/>
																						<FormControlLabel
																							value='First Class'
																							control={<Radio />}
																							label='First Class'
																						/>
																						<FormControlLabel
																							value='Premium Economy'
																							control={<Radio />}
																							label='Premium Economy'
																						/>
																					</RadioGroup>
																				</FormControl>
																			</Box>
																			<Box
																				style={{
																					background: 'darkcyan',
																					padding: 5,
																				}}
																				onClick={() => setToggle(false)}>
																				<span
																					style={{
																						color: 'white',
																						cursor: 'pointer',
																					}}>
																					Done
																				</span>
																			</Box>
																		</Box>
																	</li>
																	{/* <li></li> */}
																</ul>
															</>
														) : (
															''
														)}
													</li>
												</ul>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
												lg={2}
												style={{ marginTop: 12 }}>
												<input
													type='submit'
													value='Modify Search'
													className='btn'
													style={{
														width: '100%',
														fontSize: 16,
														borderRadius: 5,
														marginTop: 10,
														padding: 7,
													}}
												/>
											</Grid>
										</>
									) : (
										''
									)
								) : (
									<>
										<Grid
											item
											xs={12}
											md={12}
											lg={tripInfo === 'roundtrip' ? 4 : 4}>
											<Grid container alignItems='center' spacing={2}>
												<Grid
													item
													xs={12}
													lg={6}
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: matches ? 10 : '',
													}}>
													<div
														className={classes.root}
														style={{ width: '100%' }}>
														{/* <span style={{ color: "white", fontSize: ".8em" }}>
						  Leaving From
						</span> */}
														<Autocomplete
															id='country-select-demo'
															sx={{
																background: 'white',
																borderRadius: 1,
																padding: '5px',
																transition: 'none',
															}}
															value={selectedOrigin}
															// defaultValue={{
															// 	code: originp,
															// 	city: 'dfedf',
															// 	country: 'edfe',
															// }}
															clearOnBlur={true}
															PopperComponent={PopperMy}
															options={
																city && city.result.length > 0
																	? city.result
																	: []
															}
															filterOptions={(options, state) => options}
															autoHighlight
															getOptionLabel={(option) =>
																`(${option.airport_code}) ${option.city_name}, ${option.country_name} `
															}
															renderOption={(props, option) => (
																<Box
																	// mr={'5px'}
																	component='li'
																	sx={{ '& > img': { mr: 2 } }}
																	{...props}>
																	<img
																		loading='lazy'
																		width='20'
																		src={flt}
																		alt=''
																	/>
																	<p style={{ fontSize: 13 }}>
																		{option.city_name} ({option.airport_code}){' '}
																		{option.airport_name}
																	</p>
																</Box>
															)}
															onChange={(event, value) => {
																setFieldValue('Origin', value.airport_code);
																console.log(value.airport_code);
																dispatch(setSelectedOrigin(value));
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	onChange={handleSearchOrigin}
																	name='origin'
																/>
															)}
														/>
													</div>
													<img
														onClick={() => {
															dispatch(setSelectedDepart(selectedOrigin));
															dispatch(setSelectedOrigin(selectedDepart));
														}}
														src={
															'https://icons.veryicon.com/png/o/miscellaneous/mirror-icon/swap-8.png'
														}
														style={{
															height: 18,
															position: 'inherit',
															widht: '100%',
															marginTop: 20,
															marginLeft: 2,
															cursor: 'pointer',
														}}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<div className={classes.root}>
														{/* <span style={{ color: "white", fontSize: ".8em" }}>
						  Going To
						</span> */}
														<Autocomplete
															id='country-select-demo'
															sx={{
																background: 'white',
																borderRadius: 1,
																padding: '5px',
															}}
															options={
																city && city.result.length > 0
																	? city.result
																	: []
															}
															PopperComponent={PopperMy}
															value={selectedDepart}
															autoSelect={true}
															// defaultValue={{
															// 	code: departp,
															// 	city: 'dfedf',
															// 	country: 'edfe',
															// }}
															clearOnBlur={true}
															filterOptions={(options, state) => options}
															autoHighlight
															getOptionLabel={(option) =>
																`(${option.airport_code}) ${option.city_name}, ${option.country_name} `
															}
															renderOption={(props, option) => (
																<Box
																	// mr={'5px'}
																	component='li'
																	sx={{ '& > img': { mr: 2 } }}
																	{...props}>
																	<img
																		loading='lazy'
																		width='20'
																		src={flt}
																		alt=''
																	/>
																	<p style={{ fontSize: 13 }}>
																		{option.city_name} ({option.airport_code}){' '}
																		{option.airport_name}
																	</p>
																</Box>
															)}
															onChange={(event, value) => {
																setFieldValue(
																	'Destination',
																	value.airport_code
																);
																console.log(value.airport_code);
																dispatch(setSelectedDepart(value));
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	onChange={handleSearchOrigin}
																/>
															)}
														/>
													</div>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											md={12}
											lg={
												tripInfo === 'oneway'
													? 2
													: tripInfo === 'roundtrip'
													? 3
													: 2
											}>
											{/* <Box display="flex" justifyContent="space-between">
					<span style={{ color: "white", fontSize: ".8em" }}>
					  Depart Date
					</span>
					{tripInfo === "oneway" ? (
					  ""
					) : tripInfo === "roundtrip" ? (
					  <span style={{ color: "white", fontSize: ".8em" }}>
						Return Date
					  </span>
					) : (
					  ""
					)}
				  </Box> */}
											{tripInfo === 'oneway' ? (
												<OneWayDestopDate />
											) : // <></>
											tripInfo === 'roundtrip' ? (
												<DateRange />
											) : (
												<OneWayDestopDate />
											)}
										</Grid>
										<Grid
											item
											xs={12}
											md={6}
											lg={tripInfo === 'oneway' ? 3 : 2}>
											<ul
												class='menu'
												style={{
													// marginTop: 20,
													borderRadius: 2,
													paddingLeft: 0,
													cursor: 'pointer',
													background: 'white',
													// width: trip == "roundtrip" ? "110%" : "95%",
												}}>
												<li>
													<div
														onClick={() =>
															toggle ? setToggle(false) : setToggle(true)
														}
														style={{
															padding: 6,
															fontSize: matches ? 12 : '.9em',
															// borderRadius: 4,
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															fontWeight: 600,
															border: '1px solid lightgrey',
															textAlign: 'center',
														}}>
														Travellers,Classes
													</div>

													{toggle ? (
														<>
															{' '}
															<ul
																style={{
																	padding: 5,
																	width: '77%',
																	height: 250,
																}}>
																<li>
																	<Box
																		style={{ padding: 5 }}
																		display='flex'
																		justifyContent='space-between'
																		textAlign='center'
																		alignItems='center'>
																		<BodyText
																			four
																			textStyle={{
																				color: 'black',
																				fontSize: 16,
																				fontWeight: 500,
																			}}>
																			Adult
																		</BodyText>
																		<Box display='flex' alignItems='center'>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					adult === 0 ? '' : setAdult(adult - 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					-
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					{adult}
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					adult + child + infant === 9
																						? adult
																						: setAdult(adult + 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					+
																				</BodyText>
																			</Box>
																		</Box>
																	</Box>
																</li>
																<li>
																	<Box
																		style={{ padding: 5 }}
																		display='flex'
																		justifyContent='space-between'
																		textAlign='center'
																		alignItems='center'>
																		<BodyText
																			four
																			textStyle={{
																				color: 'black',
																				fontSize: 16,
																				fontWeight: 500,
																			}}>
																			Child
																		</BodyText>
																		<Box display='flex' alignItems='center'>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					child === 0 ? '' : setChild(child - 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					-
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					{child}
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					adult + child + infant === 9
																						? child
																						: setChild(child + 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					+
																				</BodyText>
																			</Box>
																		</Box>
																	</Box>
																</li>
																<li>
																	<Box
																		style={{ padding: 5 }}
																		display='flex'
																		justifyContent='space-between'
																		textAlign='center'
																		alignItems='center'>
																		<BodyText
																			four
																			textStyle={{
																				color: 'black',
																				fontSize: 16,
																				fontWeight: 500,
																			}}>
																			Infant
																		</BodyText>
																		<Box display='flex' alignItems='center'>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					infant === 0
																						? ''
																						: setInfant(infant - 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					-
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					{infant}
																				</BodyText>
																			</Box>
																			<Box
																				ml={'15px'}
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(0, 55, 114)',
																					padding: '0px 10px',
																					borderRadius: '5px',
																				}}
																				onClick={() =>
																					adult + child + infant === 9
																						? infant
																						: setInfant(infant + 1)
																				}>
																				<BodyText
																					textStyle={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					+
																				</BodyText>
																			</Box>
																		</Box>
																	</Box>
																</li>
																<li>
																	<Box
																		style={{ padding: 10 }}
																		display='flex'
																		alignItems='center'
																		justifyContent='space-between'>
																		<Box className={classes.radio2}>
																			<FormControl component='fieldset'>
																				<RadioGroup
																					aria-label='gender'
																					name='gender1'
																					value={value}
																					color='black'
																					onChange={handleRadio}>
																					<FormControlLabel
																						value='Economy'
																						control={<Radio />}
																						label='Economy'
																					/>
																					<FormControlLabel
																						value='Business'
																						control={<Radio />}
																						label='Business'
																					/>
																					<FormControlLabel
																						value='First Class'
																						control={<Radio />}
																						label='First Class'
																					/>
																					<FormControlLabel
																						value='Premium Economy'
																						control={<Radio />}
																						label='Premium Economy'
																					/>
																				</RadioGroup>
																			</FormControl>
																		</Box>
																		<Box
																			style={{
																				background: 'darkcyan',
																				padding: 5,
																			}}
																			onClick={() => setToggle(false)}>
																			<span
																				style={{
																					color: 'white',
																					cursor: 'pointer',
																					fontSize: 12,
																				}}>
																				Done
																			</span>
																		</Box>
																	</Box>
																</li>
																{/* <li></li> */}
															</ul>
														</>
													) : (
														''
													)}
												</li>
											</ul>
										</Grid>
										<Grid item xs={12} md={6} lg={2}>
											<input
												type='submit'
												value='Modify Search'
												className='btn'
												style={{
													width: '100%',
													fontSize: 13,
													borderRadius: 0,
													marginTop: 0,
													padding: 6,
												}}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default OneWayFlightForm;
